import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'react-qr-scanner';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import StyledQrReader from './styled';
import { breakpoint } from '../../utils/mixins';
import { ReactComponent as Cross } from '../../svgs/cross.svg';
import { ReactComponent as Check } from '../../svgs/check.svg';

const QRCodeReader = () => {
  const [result, setResult] = useState();
  const [alreadyExist, setAlreadyExist] = useState();
  const [user, setUser] = useState();

  const handleScan = (data = {}) => {
    if (_.get(data, 'text', false) && data?.text?.includes(process.env.REACT_APP_BACK_URL)) {
      setResult(data?.text);
    }
  };

  const handleError = (error = {}) => {
    console.error(error);
  };
  const reset = () => {
    setResult(null);
    setAlreadyExist(false);
    setUser(null);
  };

  useEffect(() => {
    if (result) {
      const check = async () => {
        try {
          // GET USER AND APP
          const userResp = await axios.get(result);
          const appResp = await axios.get(`${process.env.REACT_APP_BACK_URL}/apps/${process.env.REACT_APP_ID}`);
          // Check if user already exist
          let tmpExist = false;
          appResp?.data?.users?.forEach((item) => {
            if (item?._id === userResp?.data?._id) {
              tmpExist = true;
            }
          });
          if (tmpExist) {
            // already exist
            setAlreadyExist(true);
          } else {
            // SignUp
            await axios.post(`${process.env.REACT_APP_BACK_URL}/apps/signup`, {
              app: process.env.REACT_APP_ID,
              user: userResp?.data?._id,
            });
            setAlreadyExist(false);
          }
          setUser(userResp?.data);
          toast.success('QrCode valide !');
        } catch (e) {
          toast.error('QrCode invalide !');
          reset();
        }
      };
      check();
    }
  }, [result]);

  return (
    <StyledQrReader>
      <h1>{!user ? 'Veuillez scanner votre QrCode' : 'TakeQr'}</h1>
      {
            !result && !user && (
            <QrScanner
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: breakpoint.xxs ? '100%' : '50%' }}
            />
            )
        }

      {user && alreadyExist && (

      <div className="result-wrapper error">
        <Cross />
        <span>{`${user?.firstname} - ${user?.lastname} - ${user?.email}`}</span>
        <span>a déjà consommé(e)</span>
      </div>

      )}
      {user && !alreadyExist && (
      <div className="result-wrapper">
        <Check />
        <span>{`${user?.firstname} - ${user?.lastname} - ${user?.email}`}</span>
        <span>peut consommer</span>
      </div>
      )}

      {
        user && (
        <button type="button" onClick={() => reset()}>
          fermer
        </button>
        )
      }

    </StyledQrReader>
  );
};

export default QRCodeReader;
